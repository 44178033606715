<!-- 学员信息 -->
<template>
  <div class="main-container">
    <div class="side-header">
      <div class="name">学员信息</div>
    </div>
    <div class="main-content">
      <div class="top">
        <el-form
          :inline="true"
          ref="form"
          :model="form"
          :rules="rules"
          label-position="left"
          label-width="80px"
        >
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="姓名">
                <el-input
                  v-model="form.studentName"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="手机号">
                <el-input
                  v-model="form.telephone"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="是否安排上课">
                <el-select v-model="form.isStudy" placeholder="请选择">
                  <el-option
                    v-for="(item, index) in isStudyList"
                    :key="'study' + index"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="课程">
                <el-select v-model="form.ipType" placeholder="请选择课程">
                  <el-option
                    v-for="item in ipTypeCourseList"
                    :key="'ipType' + item.ipType"
                    :label="item.courseName"
                    :value="item.ipType"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="授课方式">
                <el-select
                  v-model="form.teach_type"
                  placehoslder="请选择授课方式"
                >
                  <el-option
                    v-for="item in teachTypeList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="getStudentList">查询</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="bottom">
        <!-- <el-form
          ref="form"
          :model="formInline"
          label-position="left"
          label-width="80px"
        >
          <el-checkbox v-model="formInline.checked"
            >已选择<span>{{ formInline.num }}</span
            >项</el-checkbox
          >
          <el-select v-model="projectNum" placeholder="请选择">
            <el-option
              v-for="item in projectList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="toggleSelection()" class="cancel"
            >取消选择</el-button>
          <el-button type="primary" class="determine">确定</el-button>
        </el-form> -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="35"></el-table-column>
          <el-table-column
            prop="studentId"
            label="ID"
            width="55"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="80"
          ></el-table-column>
          <el-table-column prop="telephone" label="手机号"></el-table-column>
          <el-table-column
            prop="courseName"
            label="课程"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="teachTypeStr"
            label="授课方式"
            width="80"
          ></el-table-column>
          <el-table-column prop="isStudyStr" label="是否安排上课">
            <template slot-scope="scope">
              <el-button size="mini" @click="onIsClass(scope.row)">{{
                scope.row.isStudyStr
              }}</el-button>
            </template>
          </el-table-column>
          <el-table-column
            prop="isFinishStr"
            label="完成状态"
          ></el-table-column>
          <el-table-column prop="studentCourseNumber" label="上课课时">
            <template slot-scope="scope">
              <el-button size="mini" @click="onAttend(scope.row)">{{
                scope.row.studentCourseNumber
              }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="状态">
            <template slot-scope="scope">
              <el-button size="mini" @click="onDetails(scope.row.studentId)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="paging">
          <el-pagination
            background
            :current-page="page"
            @current-change="getStudentList"
            layout="prev, pager, next"
            :hide-on-single-page="true"
            :page-count="totalPage"
          >
          </el-pagination>
        </div>
        <el-empty description="暂无数据" v-if="!tableData.length"></el-empty>
      </div>
    </div>

    <!-- 操作详情 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      class="dialogbox"
      :data="studentInfoData"
      width="700px"
    >
      <div class="dialog-title">
        <h5>{{ studentInfoData.name }}——详细信息</h5>
      </div>
      <div class="close" @click="dialogVisible = false">
        <img src="@/assets/icons/grayclose.png" />
      </div>
      <div class="dialog-content">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">姓名：</div>
              <div class="text">{{ studentInfoData.name }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">生日：</div>
              <div class="text">
                {{ studentInfoData.birthday | stringData }}
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">邮箱：</div>
              <div class="text">{{ studentInfoData.email }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">手机号：</div>
              <div class="text">{{ studentInfoData.telephone }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="items flex flex-start">
              <div class="label">地址：</div>
              <div class="text">{{ studentInfoData.address }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">学校：</div>
              <div class="text">{{ studentInfoData.school }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">学历：</div>
              <div class="text">{{ studentInfoData.education }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="items">
              <div class="label">毕业证或学生证：</div>
              <div class="img flex flex-start">
                <img :src="studentInfoData.diplomaImage1" />
                <img :src="studentInfoData.diplomaImage2" />
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">社会人士或在校学生：</div>
              <div class="text">{{ studentInfoData.inSchoolStr }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">工作年限：</div>
              <div class="text">{{ studentInfoData.workYear }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">课程选择：</div>
              <div class="text">{{ studentInfoData.courseName }}</div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="items flex flex-start">
              <div class="label">授课方式：</div>
              <div class="text">{{ studentInfoData.teachTypeStr }}</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 操作详情 -->
    <!-- 考勤状态 -->
    <el-dialog
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      class="dialogbox dialogbox2"
      width="700px"
    >
      <div class="dialog-title">
        <h5>{{ currentData.name }}——上课课时</h5>
      </div>
      <div class="close" @click="dialogVisible2 = false">
        <img src="@/assets/icons/grayclose.png" />
      </div>
      <div class="dialog-content">
        <el-form :inline="true" label-position="left" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="课时">
                <el-select
                  v-model="attendForm.courseNumber"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in attendList"
                    :key="item.value"
                    :value="item.value"
                    :label="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="备注">
                <el-input
                  v-model="attendForm.remarks"
                  type="textarea"
                  :rows="3"
                  placeholder=""
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                size="mini"
                class="attendBtn"
                v-preventReClick
                @click="onSubmitAttend"
                >确认</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogIsClass"
      :close-on-click-modal="false"
      class="dialogbox dialogbox2"
      width="700px"
    >
      <div class="dialog-title">
        <h5>{{ currentData.name }}——安排上课</h5>
      </div>
      <div class="close" @click="dialogIsClass = false">
        <img src="@/assets/icons/grayclose.png" />
      </div>
      <div class="dialog-content">
        <el-form :inline="true" label-position="left" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="安排上课">
                <el-radio v-model="isClassForm.isStudy" label="1"
                  >已安排</el-radio
                >
                <el-radio v-model="isClassForm.isStudy" label="2"
                  >未安排</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                size="mini"
                class="attendBtn"
                v-preventReClick
                @click="onSubmitClass"
                >确认</el-button
              >
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
    <!-- 考勤状态 -->
  </div>
</template>

<script>
import moment from "moment";
import { deleteField } from "@/utils";
export default {
  filters: {
    stringData: function (date) {
      return moment(date * 1000).format("YYYY-MM-DD");
    },
    //是否安排上课
    studyFilter(state) {
      const studentMap = {
        0: "否",
        1: "是",
        2: "全部",
      };
      return studentMap[state];
    },
  },
  data() {
    return {
      form: {
        studentName: "",
        telephone: "",
        isStudy: null,
        ipType: null,
        teachType: null,
      }, //查询信息
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
      },
      isStudyList: [
        {
          label: "未安排",
          value: 0,
        },
        {
          label: "已安排",
          value: 1,
        },
      ],
      ipTypeCourseList: [],
      teachTypeList: [
        {
          label: "线上",
          value: 1,
        },
        {
          label: "线下",
          value: 2,
        },
      ],
      currentData: {},
      attendForm: {
        studentId: "",
        courseNumber: 1,
        remarks: "",
        ipType: "",
      },
      isClassForm: {
        studentId: "",
        isStudy: "",
      },
      projectNum: [],
      projectList: [],
      tableData: [], //学员信息表格
      studentInfoData: [], //学员详细信息
      multipleSelection: [],
      dialogVisible: false,
      dialogVisible2: false,
      dialogIsClass: false,
      gridData: [],
      attendList: [],
      page: 1,
      totalPage: 0,
    };
  },
  created() {
    this.getCommonList();
    this.getStudentList(this.page); //学员信息列表
  },
  methods: {
    getCommonList() {
      this.$http.get("/api/web/common/getCommonList").then((res) => {
        this.ipTypeCourseList = res.data.ipTypeCourseList;
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val);
    },
    //获取学员信息接口
    getStudentList(page) {
      const data = deleteField(Object.assign(this.form, { page: page }));
      this.$http.post(`/api/web/student/getStudentList`, data).then((res) => {
        this.tableData = res.data.studentList;
        this.totalPage = res.data.totalPage;
      });
    },
    //获取学员详情接口
    getStudentInfo(id) {
      this.$http
        .get("/api/web/student/getStudentInfo?studentId=" + id)
        .then((res) => {
          this.studentInfoData = res.data.studentInfo;
        });
    },
    //考勤状态提交
    onSubmitAttend(id) {
      this.$http
        .post("/api/web/student/finishCourse", this.attendForm)
        .then((res) => {
          this.$toast(res.msg);
          this.getStudentList();
          setTimeout(() => {
            this.dialogVisible2 = false;
          }, 1000);
        })
        .catch((err) => {
          this.$toast(err.msg);
        });
    },
    onSubmitClass() {
      this.$http
        .post("/api/web/student/studyCourse", this.isClassForm)
        .then((res) => {
          this.$toast(res.msg);
          this.getStudentList();
          setTimeout(() => {
            this.dialogIsClass = false;
          }, 1000);
        })
        .catch((err) => {
          this.$toast(err.msg);
        });
    },
    //查询学生信息
    onDetails(id) {
      this.getStudentInfo(id);
      this.dialogVisible = true;
    },
    onIsClass(item) {
      this.dialogIsClass = true;
      this.isClassForm.studentId = item.studentId;
      this.isClassForm.isStudy = String(item.isStudy);
    },
    onAttend(item) {
      // this.getStudentInfo(id);
      this.currentData = item;
      this.attendForm.studentId = item.studentId;
      this.attendForm.ipType = item.ipType;
      for (let i = 0; i < item.courseNumber; i++) {
        this.attendList.push({
          label: i + 1 + "节",
          value: i + 1,
        });
      }
      this.dialogVisible2 = true;
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .side-header {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 68px;
    padding: 0 20px 0 40px;
    border-bottom: 1px solid #f5f5f5;
    .name {
      font-size: 16px;
      color: #333;
    }
  }
  .main-content {
    /deep/.top {
      background: #fff;
      margin-bottom: 10px;
      padding: 30px 40px 8px;
      .el-button {
        float: right;
        width: 140px;
      }
      .el-form-item {
        width: 100%;
        margin-right: 0;
        .el-form-item__label {
          text-align: right;
          width: 100px !important;
        }
      }
      .el-form-item__content {
        width: calc(100% - 100px);
        .el-select {
          width: 100%;
        }
      }
    }
    /deep/.bottom {
      background: #fff;
      padding: 30px 40px 8px;
      text-align: left;
      min-height: 435px;
      .el-form {
        padding-bottom: 30px;
        border-bottom: solid 2px #dedede;
      }
      .el-checkbox,
      .el-select {
        margin-right: 10px;
      }
      .cancel,
      .determine {
        width: 140px;
      }
      .cancel {
        background-color: white;
        color: #d71a18;
      }
      .el-table {
        thead {
          color: #333;
        }
        .cell {
          text-align: center;
          white-space: nowrap;
        }
        th.el-table__cell > .cell {
          text-align: center;
        }
        .el-button {
          border: none;
          font-size: 14px;
        }
      }
    }
    .paging {
      width: 100%;
      margin-top: 20px;
      text-align: center;
      /deep/.el-pager li {
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
        background-color: white;
      }
      /deep/.el-pager li:not(.disabled).active {
        background-color: #d71a18;
        color: #fff;
      }
      /deep/.btn-next,
      /deep/.btn-prev {
        background-color: white;
        font-size: 16px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: normal;
      }
    }
  }
  /deep/.dialogbox {
    text-align: left;
    .el-dialog {
      width: 100%;
    }
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 36px;
      height: 36px;
      cursor: pointer;
      img {
        display: block;
        width: 36px;
        height: 36px;
      }
    }
    .dialog-title {
      font-size: 18px;
      text-align: center;
      color: #666666;
      padding: 35px 40px;
      border-bottom: solid 2px #dedede;
      h5 {
        margin-top: 0;
        margin: 0;
      }
    }
    .dialog-content {
      text-align: left;
      padding: 30px 40px;
      .items {
        margin-bottom: 10px;
        .label {
          font-size: 16px;
          height: 36px;
          line-height: 36px;
          color: #666;
        }
        .text {
          font-size: 16px;
          height: 36px;
          line-height: 36px;
          color: #333;
        }
        .img {
          img {
            display: block;
            width: 184px;
            margin-right: 30px;
          }
        }
      }
    }
  }
  /deep/.dialogbox2 {
    .el-form {
      text-align: left;
      border-bottom: none;
      .el-form-item {
        width: 100%;
        .el-form-item__content {
          width: calc(100% - 80px);
        }
      }
      .attendBtn {
        display: block;
        width: 160px;
        height: 48px;
        line-height: 48px;
        background: #d71a18;
        border-radius: 4px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        margin: 0 auto;
        padding: 0;
      }
    }
  }
}
</style>
