/** 判断屏幕大小 */
export function judgeBigScreen() {
  //，这里根据返回值 true 或false ,返回true的话 则为全面屏
  let result = false;
  const rate = window.screen.height / window.screen.width;
  let limit = window.screen.height == window.screen.availHeight ? 1.8 : 1.65; // 临界判断值

  if (rate > limit) {
    result = true;
  }
  return result;
}
export const isX = judgeBigScreen()

// 删除空字段
export function deleteField(obj){
  Object.keys(obj).forEach(item=>{
      if(!obj[item])  delete obj[item]
  })
  return obj;
}

// 验证手机号的格式
export function phoneValid(phone) {
  const reg = /^1[3456789]\d{9}$/
  return reg.test(phone)
}

export function isWX(){ 
  var ua = navigator.userAgent.toLowerCase(); 
  if(ua.match(/MicroMessenger/i)=="micromessenger") { 
    return true; 
  } else { 
    return false; 
  } 
}

export function guid() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
}

